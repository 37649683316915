// src/components/ConfirmDeleteModal.js

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

const ConfirmDeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  collectionName,
  count,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={isLoading ? () => {} : onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="#2d2d2d" color="white">
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalCloseButton disabled={isLoading} />
        <ModalBody>
          <Text>
            Are you sure you want to delete the collection{" "}
            <strong>{collectionName}</strong> containing{" "}
            <strong>
              {count} {count === 1 ? "document" : "documents"}
            </strong>
            ? This action cannot be undone.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={onConfirm}
            isLoading={isLoading}
            loadingText="Deleting"
            disabled={isLoading}
          >
            Delete
          </Button>
          <Button variant="ghost" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteModal;
