import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  HStack,
  Box,
  Avatar,
  Button,
  useBreakpointValue,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useNavigate } from "react-router-dom";

const MessagesDisplay = ({ messages, isTyping, handleSendMessage }) => {
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  // Modal controls
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Track whether appointment is completed and if "Booking Completed" button was clicked
  const [appointmentCompleted, setAppointmentCompleted] = useState(false);
  const [showYesNo, setShowYesNo] = useState(false);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [typingText, setTypingText] = useState("");
  const [isTypingEffect, setIsTypingEffect] = useState(false);

  // For typing effect in each message
  useEffect(() => {
    let typingInterval;
    if (currentMessageIndex < messages.length) {
      setIsTypingEffect(true);
      const currentMessage = messages[currentMessageIndex]?.content || "";

      let charIndex = 0;
      setTypingText(currentMessage[charIndex] || ""); // Start with the first character
      charIndex += 1;

      typingInterval = setInterval(() => {
        if (charIndex < currentMessage.length) {
          setTypingText((prev) =>
            currentMessage[charIndex] ? prev + currentMessage[charIndex] : prev
          );
          charIndex += 1;
        }

        if (charIndex >= currentMessage.length) {
          clearInterval(typingInterval);
          setTimeout(() => {
            setVisibleMessages((prev) => [
              ...prev,
              messages[currentMessageIndex],
            ]);
            setTypingText("");
            setIsTypingEffect(false);
            setCurrentMessageIndex((prev) => prev + 1);
          }, 300);
        }
      }, 20);
    }
    return () => clearInterval(typingInterval); // Cleanup interval on component unmount or when dependencies change
  }, [currentMessageIndex, messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [visibleMessages]);

  const handleBookAppointment = () => {
    onOpen(); // Open the booking modal
  };

  const handleBookingCompletedClick = () => {
    setShowYesNo(true); // Show Yes/No options when clicked
  };

  const handleYes = () => {
    setAppointmentCompleted(true);
    onClose();
    setShowYesNo(false);
    handleSendMessage("Booking completed. What would you like to do next?");
  };

  const handleNo = () => {
    onClose();
    setShowYesNo(false);
    handleSendMessage("Went back without booking.");
  };

  const isAppointmentRelated = (message) => {
    const appointmentKeywords = [
      "book",
      "appointment",
      "consultation",
      "schedule",
    ];
    return appointmentKeywords.some((keyword) =>
      message.toLowerCase().includes(keyword)
    );
  };

  const buttonWidth = useBreakpointValue({ base: "80%", md: "50%" });

  const renderMessages = () => {
    // Get the latest bot message index
    const latestBotMessageIndex = visibleMessages
      .map((msg, index) => ({ ...msg, index }))
      .filter((msg) => msg.role === "system")
      .pop()?.index;

    return visibleMessages.map((msg, index) => (
      <VStack
        key={index}
        alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
        width="100%"
        spacing={2}
      >
        <HStack
          alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
          bg={msg.role === "user" ? "blue.500" : "gray.100"}
          borderRadius="md"
          padding={3}
          maxWidth="80%"
          alignItems="center"
          color={msg.role === "user" ? "white" : "black"}
          flexDirection="column"
          boxShadow="md"
        >
          <HStack alignItems="center" width="100%">
            <Avatar
              name={msg.role === "user" ? "User" : "Bot"}
              size="sm"
              mr={2}
            />
            <Box flex="1">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {msg.content}
              </ReactMarkdown>
            </Box>
          </HStack>

          {/* Only display suggestions for the latest bot message */}
          {msg.role === "system" &&
            index === latestBotMessageIndex &&
            msg.suggestions?.length > 0 && (
              <HStack mt={2} justifyContent="center" width="100%" spacing={2}>
                {msg.suggestions?.map((suggestion, idx) => (
                  <Button
                    key={idx}
                    colorScheme="blue"
                    size="sm"
                    variant="outline"
                    borderColor="blue.500"
                    borderRadius="full"
                    width={buttonWidth}
                    onClick={() => handleSendMessage(suggestion)}
                  >
                    {suggestion.replace(/^.*?"/, "").replace(/"$/, "")}
                  </Button>
                ))}
              </HStack>
            )}

          {/* Only show the Book Appointment button if the appointment hasn't been completed */}
          {msg.role === "system" &&
            isAppointmentRelated(msg.content) &&
            !appointmentCompleted && (
              <Button
                colorScheme="blue"
                size="sm"
                mt={2}
                borderRadius="full"
                width={buttonWidth}
                onClick={handleBookAppointment}
                boxShadow="md"
              >
                Book Appointment
              </Button>
            )}
        </HStack>
      </VStack>
    ));
  };

  return (
    <Flex direction="column" width="100%" height="90vh" bg="white">
      <VStack
        flex="1"
        overflowY="auto"
        spacing={4}
        bg="white"
        css={{
          "::-webkit-scrollbar": { width: "8px" },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-track": { background: "#f2f2f2" },
        }}
        mt="70px"
        mb="80px"
      >
        {renderMessages()}
        {/* Typing effect */}
        {isTypingEffect &&
          typingText && ( // Fix: only show typing text if available
            <HStack
              alignSelf="flex-start"
              bg="gray.100"
              borderRadius="md"
              padding={3}
              maxWidth="80%"
              alignItems="center"
              color="black"
            >
              <Avatar name="Bot" size="sm" mr={2} />
              <Box>{typingText}</Box>
            </HStack>
          )}
        {isTyping && (
          <HStack
            alignSelf="flex-start"
            bg="gray.100"
            borderRadius="md"
            padding={3}
            maxWidth="80%"
            alignItems="center"
            color="black"
          >
            <Avatar name="Bot" size="sm" mr={2} />
            <Box>...</Box>
          </HStack>
        )}
        <div ref={messagesEndRef} />
      </VStack>

      {/* Modal for Booking Appointment */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent maxWidth="80vw" height="80vh">
          <ModalHeader>Book Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <iframe
              src="https://api.leadconnectorhq.com/widget/booking/8aInC98eTtF0Y5SxGX0H"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                overflow: "auto",
              }}
              id="8aInC98eTtF0Y5SxGX0H_1725640787640"
              title="Appointment Booking"
            ></iframe>
          </ModalBody>
          <ModalFooter>
            {!showYesNo ? (
              <Button
                colorScheme="green"
                mr={3}
                onClick={handleBookingCompletedClick}
              >
                Appointment scheduled?
              </Button>
            ) : (
              <>
                <Button colorScheme="green" mr={3} onClick={handleYes}>
                  Yes
                </Button>
                <Button variant="ghost" onClick={handleNo}>
                  No
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MessagesDisplay;
